// TokenSearch.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const styles = {
  tokenSearch: {
    margin: '20px auto',
    maxWidth: '600px',
    textAlign: 'center',
  },
  searchBar: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '20px',
  },
  searchInput: {
    padding: '10px',
    width: '70%',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  searchButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  searchButtonDisabled: {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
  },
  searchResults: {
    marginTop: '20px',
  },
  tokenResult: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginBottom: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  tokenResultHover: {
    backgroundColor: '#f9f9f9',
  },
  tokenName: {
    fontWeight: 'bold',
  },
  tokenSymbol: {
    color: '#666',
  },
  tokenPrice: {
    color: '#28a745',
  },
  errorMessage: {
    color: '#dc3545',
    marginTop: '10px',
  },
};

const TokenSearch = () => {
  const [tokenAddress, setTokenAddress] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSearch = async () => {
    if (!tokenAddress) return;

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`
      );

      if (response.data && response.data.pairs.length > 0) {
        const tokenDetails = response.data.pairs[0];
        setSearchResults([{
          name: tokenDetails.baseToken.name || 'Unknown',
          symbol: tokenDetails.baseToken.symbol || 'N/A',
          priceUsd: tokenDetails.priceUsd || 'N/A',
          address: tokenAddress,
        }]);
      } else {
        setError('Token data not available.');
      }
    } catch (err) {
      console.error('Error fetching token data:', err);
      setError('Failed to fetch token data.');
    } finally {
      setLoading(false);
    }
  };

  const handleTokenClick = (token) => {
    navigate(`/token/${token.address}`, { state: { token } });
  };

  return (
    <div style={styles.tokenSearch}>
      <div style={styles.searchBar}>
        <input
          type="text"
          placeholder="Paste token contract address"
          value={tokenAddress}
          onChange={(e) => setTokenAddress(e.target.value)}
          style={styles.searchInput}
        />
        <button
          onClick={handleSearch}
          disabled={loading}
          style={loading ? { ...styles.searchButton, ...styles.searchButtonDisabled } : styles.searchButton}
        >
          {loading ? 'Searching...' : 'Search'}
        </button>
      </div>

      {error && <div style={styles.errorMessage}>{error}</div>}

      {searchResults.length > 0 && (
        <div style={styles.searchResults}>
          {searchResults.map((token, index) => (
            <div
              key={index}
              style={styles.tokenResult}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.tokenResultHover.backgroundColor}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
              onClick={() => handleTokenClick(token)}
            >
              <div style={styles.tokenName}>{token.name}</div>
              <div style={styles.tokenSymbol}>{token.symbol}</div>
              <div style={styles.tokenPrice}>${token.priceUsd}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TokenSearch;