import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaCopy } from 'react-icons/fa'; // Import copy icon
import Banners from '../components/Banners'; // Import the Banner component
import { banners } from '../components/bannersData'; // Import the shared banners data
import EthTrending from '../components/EthTrending'; // Import EthTrending component
import SolTrending from '../components/SolTrending'; // Import SolTrending component
import TonTrending from '../components/TonTrending'; // Import TonTrending component
import TokenSearch from '../components/TokenSearch';

function TokenPage() {
  const { tokenAddress } = useParams();
  const location = useLocation();

  const [tokenData, setTokenData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Access token details (logo, website, twitter, telegram) from location.state
  const tokenDetails = location.state?.token || {};
  const { logo: tokenLogo, website, twitter, telegram } = tokenDetails;

  console.log('Token Details:', tokenDetails); // Debugging statement

  const [votes, setVotes] = useState(() => {
    const savedVotes = localStorage.getItem(`votes_${tokenAddress}`);
    return savedVotes
      ? JSON.parse(savedVotes)
      : { rocket: 0, fire: 0, boo: 0, flag: 0 };
  });

  const [lastVoteTimestamp, setLastVoteTimestamp] = useState(() => {
    const savedTimestamp = localStorage.getItem(`lastVoteTimestamp_${tokenAddress}`);
    return savedTimestamp ? JSON.parse(savedTimestamp) : {};
  });

  const formatNumber = (num) => {
    if (num >= 1_000_000) {
      return `<strong>${(num / 1_000_000).toFixed(1)}M</strong>`;
    } else if (num >= 1_000) {
      return `<strong>${(num / 1_000).toFixed(1)}K</strong>`;
    } else {
      return `<strong>${num.toFixed(1)}</strong>`;
    }
  };

  const handleVote = (type) => {
    const now = Date.now();
    const lastVoteTime = lastVoteTimestamp[type] || 0;

    if (now - lastVoteTime < 60 * 60 * 1000) {
      alert(`You can only vote once per hour for ${type}.`);
      return;
    }

    const updatedVotes = { ...votes, [type]: votes[type] + 1 };
    setVotes(updatedVotes);

    const updatedTimestamp = { ...lastVoteTimestamp, [type]: now };
    setLastVoteTimestamp(updatedTimestamp);

    localStorage.setItem(`votes_${tokenAddress}`, JSON.stringify(updatedVotes));
    localStorage.setItem(`lastVoteTimestamp_${tokenAddress}`, JSON.stringify(updatedTimestamp));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Contract address copied to clipboard!');
    });
  };

  const getExplorerLink = () => {
    switch (tokenData?.chainId) {
      case 'ethereum':
        return `https://etherscan.io/token/${tokenAddress}`;
      case 'solana':
        return `https://solscan.io/token/${tokenAddress}`;
      case 'ton':
        return `https://tonviewer.com/${tokenAddress}`;
      default:
        return '#';
    }
  };

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await axios.get(
          `https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`
        );

        if (response.data && response.data.pairs.length > 0) {
          const tokenDetails = response.data.pairs[0];
          setTokenData({
            name: tokenDetails.baseToken.name || 'Unknown',
            symbol: tokenDetails.baseToken.symbol || 'N/A',
            priceUsd: tokenDetails.priceUsd || 'N/A',
            hourChange: tokenDetails.priceChange?.h1 || 'N/A',
            dayChange: tokenDetails.priceChange?.h24 || 'N/A',
            liquidity: tokenDetails.liquidity?.usd || 'N/A',
            marketCap: tokenDetails.fdv || 'N/A',
            volume24h: tokenDetails.volume?.h24 || 'N/A',
            fdv: tokenDetails.fdv || 'N/A',
            chainId: tokenDetails.chainId || 'ethereum',
          });
        } else {
          setError('Token data not available.');
        }
      } catch (err) {
        console.error('Error fetching token data:', err);
        setError('Failed to fetch token data.');
      } finally {
        setLoading(false);
      }
    };

    fetchTokenData();

    const intervalId = setInterval(fetchTokenData, 10000);

    return () => clearInterval(intervalId);
  }, [tokenAddress]);

  useEffect(() => {
    if (tokenData) {
      document.title = `${tokenData.symbol} | $${tokenData.priceUsd}`;
    }
  }, [tokenData]);

  const getSwapWidgetUrl = () => {
    switch (tokenData?.chainId) {
      case 'ethereum':
        return `https://app.uniswap.org/#/swap?chain=mainnet&inputCurrency=${tokenAddress}`;
      case 'solana':
        return `https://jup.ag/swap/${tokenAddress}`;
      case 'ton':
        return `https://app.ston.fi/swap?ft=${tokenAddress}`;
      default:
        return null;
    }
  };

  if (loading) return <div>Loading token data...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="rounded-div my-12 py-8 text-primary">
      <TokenSearch />
      <Banners banners={banners} />
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          {tokenLogo ? (
            <img
              src={tokenLogo}
              alt={tokenData?.name || 'Token Logo'}
              className="w-10 h-10 mr-4"
              onError={(e) => {
                e.target.style.display = 'none';
              }}
            />
          ) : (
            <div className="w-10 h-10 mr-4 bg-gray-300 rounded-full flex items-center justify-center">
              <span role="img" aria-label="token">
                💎
              </span>
            </div>
          )}
          <div>
            <h1 className="text-2xl font-bold">{tokenData?.name} Details</h1>
            <p>Symbol: {tokenData?.symbol}</p>
          </div>
        </div>
        <div className="flex space-x-2">
          {website && (
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Website
            </a>
          )}
          {twitter && (
            <a
              href={`https://twitter.com/${twitter}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Twitter
            </a>
          )}
          {telegram && (
            <a
              href={`https://t.me/${telegram}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Telegram
            </a>
          )}
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-8 mb-8">
        <div className="rounded-div p-6 col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-xl font-bold mb-4">Token Details</h2>
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <p>Contract:</p>
                  <a
                    href={getExplorerLink()}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {`${tokenAddress.slice(0, 5)}...${tokenAddress.slice(-4)}`}
                  </a>
                  <button
                    onClick={() => copyToClipboard(tokenAddress)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <FaCopy />
                  </button>
                </div>

                <p>Price: {tokenData.priceUsd !== 'N/A' ? `$${tokenData.priceUsd}` : 'N/A'}</p>
                <p>
                  Hourly Change:{' '}
                  <span className={tokenData.hourChange > 0 ? 'text-green-500' : 'text-red-500'}>
                    {tokenData.hourChange !== 'N/A'
                      ? `${tokenData.hourChange > 0 ? '+' : ''}${tokenData.hourChange}%`
                      : 'N/A'}
                  </span>
                </p>
                <p>
                  Daily Change:{' '}
                  <span className={tokenData.dayChange > 0 ? 'text-green-500' : 'text-red-500'}>
                    {tokenData.dayChange !== 'N/A'
                      ? `${tokenData.dayChange > 0 ? '+' : ''}${tokenData.dayChange}%`
                      : 'N/A'}
                  </span>
                </p>
                <p>
                  Liquidity:{' '}
                  {tokenData.liquidity !== 'N/A' ? (
                    <span dangerouslySetInnerHTML={{ __html: `$${formatNumber(tokenData.liquidity)}` }} />
                  ) : (
                    'N/A'
                  )}
                </p>
                <p>
                  Market Cap:{' '}
                  {tokenData.marketCap !== 'N/A' ? (
                    <span dangerouslySetInnerHTML={{ __html: `$${formatNumber(tokenData.marketCap)}` }} />
                  ) : (
                    'N/A'
                  )}
                </p>
                <p>
                  24h Trading Volume:{' '}
                  {tokenData.volume24h !== 'N/A' ? (
                    <span dangerouslySetInnerHTML={{ __html: `$${formatNumber(tokenData.volume24h)}` }} />
                  ) : (
                    'N/A'
                  )}
                </p>
                <p>
                  FDV:{' '}
                  {tokenData.fdv !== 'N/A' ? (
                    <span dangerouslySetInnerHTML={{ __html: `$${formatNumber(tokenData.fdv)}` }} />
                  ) : (
                    'N/A'
                  )}
                </p>
              </div>
            </div>

            <div>
              {/* Replace the existing ad banner with the new banner */}
              <div style={{ width: '300px', height: '250px', border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden' }}>
                <a href="https://defigem.app/promotion" target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://defigem.app/DefiGem-app-300.gif"
                    alt="Advertisement Banner"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </a>
              </div>

              <div className="mt-4 flex space-x-4">
                <button
                  onClick={() => handleVote('rocket')}
                  className="flex items-center space-x-2 hover:opacity-80"
                >
                  <span role="img" aria-label="rocket" className="text-2xl">
                    🚀
                  </span>
                  <span className="text-xl font-semibold">{votes.rocket}</span>
                </button>
                <button
                  onClick={() => handleVote('fire')}
                  className="flex items-center space-x-2 hover:opacity-80"
                >
                  <span role="img" aria-label="fire" className="text-2xl">
                    🔥
                  </span>
                  <span className="text-xl font-semibold">{votes.fire}</span>
                </button>
                <button
                  onClick={() => handleVote('boo')}
                  className="flex items-center space-x-2 hover:opacity-80"
                >
                  <span role="img" aria-label="boo" className="text-2xl">
                    👻
                  </span>
                  <span className="text-xl font-semibold">{votes.boo}</span>
                </button>
                <button
                  onClick={() => handleVote('flag')}
                  className="flex items-center space-x-2 hover:opacity-80"
                >
                  <span role="img" aria-label="flag" className="text-2xl">
                    🚩
                  </span>
                  <span className="text-xl font-semibold">{votes.flag}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-div p-6 col-span-1 border border-gray-300 rounded-lg">
          <h2 className="text-xl font-bold mb-4">Swap</h2>
          {getSwapWidgetUrl() ? (
            <iframe
              title={`Swap ${tokenData.symbol} on ${tokenData.chainId}`}
              src={getSwapWidgetUrl()}
              width="100%"
              height="300"
              style={{ border: 'none', borderRadius: '8px' }}
            />
          ) : (
            <p>Swap not available for this chain.</p>
          )}
        </div>
      </div>

      <div className="rounded-div p-6">
        <h2 className="text-xl font-bold mb-4">Price Chart</h2>
        <iframe
          title={`${tokenData.name} Price Chart`}
          src={`https://dexscreener.com/${tokenData.chainId}/${tokenAddress}?embed=1&info=0`}
          width="100%"
          height="700"
          style={{ border: 'none', borderRadius: '8px' }}
        />
      </div>

      {/* Display Trending Component based on Chain */}
      {tokenData?.chainId === 'ethereum' && <EthTrending />}
      {tokenData?.chainId === 'solana' && <SolTrending />}
      {tokenData?.chainId === 'ton' && <TonTrending />}
    </div>
  );
}

export default TokenPage;