// Banners.js
import React from 'react';

const Banners = ({ banners }) => {
  return (
    <div className="flex flex-col items-center space-y-4 mb-8">
      {banners.map((banner, index) => (
        <a key={index} href={banner.link} target="_blank" rel="noopener noreferrer">
          <img src={banner.url} alt={`Banner ${index}`} style={{ width: '1000px', height: '110px' }} />
        </a>
      ))}
    </div>
  );
};

export default Banners;