import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function EthTrending() {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Manually add Ethereum-based token data (with website and social links)
  const tokenData = useMemo(() => [
    
    {
      address: '0xB5274D9F803B4b15607c6E180EF8f68fE990F73b', // USDT
      logo: 'https://dd.dexscreener.com/ds-data/tokens/ethereum/0xb5274d9f803b4b15607c6e180ef8f68fe990f73b.png?size=lg&key=55b47b',
      website: 'https://tether.to/', // Full URL
      twitter: 'Tether_to', // Only the handle (no URL)
      telegram: 'tether_to', // Only the handle (no URL)
    },
    {
      address: '0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a', // USDT
      logo: 'https://dd.dexscreener.com/ds-data/tokens/ethereum/0xaaee1a9723aadb7afa2810263653a34ba2c21c7a.png?size=lg&key=deb851',
      website: 'https://tether.to/', // Full URL
      twitter: 'Tether_to', // Only the handle (no URL)
      telegram: 'tether_to', // Only the handle (no URL)
    },
    {
      address: '0xadd39272E83895E7d3f244f696B7a25635F34234', // USDT
      logo: 'https://dd.dexscreener.com/ds-data/tokens/ethereum/0xadd39272e83895e7d3f244f696b7a25635f34234.png?size=lg&key=115a72',
      website: 'https://tether.to/', // Full URL
      twitter: 'Tether_to', // Only the handle (no URL)
      telegram: 'tether_to', // Only the handle (no URL)
    },
    
    {
      address: '0x26E550AC11B26f78A04489d5F20f24E3559f7Dd9', // MATIC
      logo: 'https://dd.dexscreener.com/ds-data/tokens/ethereum/0x26e550ac11b26f78a04489d5f20f24e3559f7dd9.png?size=lg&key=0d93ef',
      website: 'https://polygon.technology/', // Full URL
      twitter: '0xPolygon', // Only the handle (no URL)
      telegram: 'polygonnetwork', // Only the handle (no URL)
    },
  ], []);

  // Fetch data for all tokens
  const fetchTokenData = useCallback(async () => {
    try {
      const promises = tokenData.map((token) =>
        axios.get(`https://api.dexscreener.com/latest/dex/tokens/${token.address}`)
      );

      const responses = await Promise.all(promises);

      const tokensWithDetails = responses.map((response, index) => {
        if (response.data && response.data.pairs.length > 0) {
          const pair = response.data.pairs[0];
          return {
            ...tokenData[index], // Include all properties from tokenData
            name: pair.baseToken.name || 'Unknown',
            symbol: pair.baseToken.symbol || 'N/A',
            priceUsd: pair.priceUsd || 'N/A',
            hourChange: pair.priceChange?.h1 || 'N/A',
            website: tokenData[index].website || null, // Use tokenData website as fallback
            twitter: tokenData[index].twitter || null, // Use tokenData twitter as fallback
            telegram: tokenData[index].telegram || null, // Use tokenData telegram as fallback
          };
        } else {
          return {
            ...tokenData[index], // Include all properties from tokenData
            name: 'Unknown',
            symbol: 'N/A',
            priceUsd: 'N/A',
            hourChange: 'N/A',
            website: tokenData[index].website || null, // Use tokenData website as fallback
            twitter: tokenData[index].twitter || null, // Use tokenData twitter as fallback
            telegram: tokenData[index].telegram || null, // Use tokenData telegram as fallback
          };
        }
      });

      setTokens(tokensWithDetails);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching token data:', err);
      setError('Failed to fetch token data.');
      setLoading(false);
    }
  }, [tokenData]);

  useEffect(() => {
    fetchTokenData();
  }, [fetchTokenData]);

  if (loading) return <div>Loading tokens...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="rounded-div my-12 py-4 text-primary">
      <div className="flex items-center">
        <img
          src="https://cryptologos.cc/logos/ethereum-eth-logo.png"
          alt="Ethereum"
          className="w-10 h-10 mr-4"
        />
        <h1 className="text-2xl font-bold py-4">ETH Trending </h1>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        {tokens.map((token, idx) => (
          <Link
            to={{
              pathname: `/token/${token.address}`,
              state: { token }, // Pass the entire token object
            }}
            key={idx}
          >
            <div className="rounded-div p-4 hover:scale-105 transition-transform">
              <div className="flex items-center">
                <img src={token.logo} alt={token.name} className="w-10 h-10 mr-4" />
                <div>
                  <p className="font-bold">{token.name}</p>
                  <p>Symbol: {token.symbol}</p>
                  <p>Price: {token.priceUsd !== 'N/A' ? `$${token.priceUsd}` : 'N/A'}</p>
                  <p>
                    Past Hour:{' '}
                    <span className={token.hourChange > 0 ? 'text-green-500' : 'text-red-500'}>
                      {token.hourChange !== 'N/A'
                        ? `${token.hourChange > 0 ? '+' : ''}${token.hourChange}%`
                        : 'N/A'}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default EthTrending;