// HomePage.js
import React from 'react';
import CoinSearch from '../components/CoinSearch';
import TrendingCoins from '../components/TrendingCoins';
import EthTrending from '../components/EthTrending';
import SolTrending from '../components/SolTrending';
import TonTrending from '../components/TonTrending';
import Home3d from '../components/three/Home3d';
import PromoBanner from '../components/PromoBanner';
import Promo2 from '../components/Promo2';
import ImageSlider from '../components/Carousel';
import Banners from '../components/Banners'; // Import the Banner component
import { banners } from '../components/bannersData'; // Import the shared banners data
import TokenSearch from '../components/TokenSearch';

const slides = [
  { url: "./smarten-Telegram-Trading-bot-DEFIGEM-app.png", link: "https://defigem.app/SmartenAITradingBot" },
  { url: "./Meta-Force-Space-DEFIGEM-App.png", link: "https://defigem.app/HoliverseCoinMetaverseExplained" },
  { url: "./Maestro-Trading-Sniper-Bots-1000x.png", link: "https://defigem.app/MaestroTradingSniperBot" },
  { url: "./Solana-Trading-Sniper-Bots.png", link: "https://defigem.app/SolanaTradingSniperBots" },
  { url: "./Defi-Gem-Ads.png", link: "https://t.me/DefiDevelope" },
];

const containerStyles = {
  width: "1000px",
  height: "330px",
  margin: "0 auto",
};

function HomePage({ coins }) {
  return (
    <div>
      <div className='h-[200px]'>
        <Home3d />
      </div>
      <div style={containerStyles}>
        <ImageSlider slides={slides} parentWidth={500} />
      </div>
      <br />
      <br />
      <br />
      <Banners banners={banners} /> {/* Add the Banner component */}
      
      <br />
      <br />
      <PromoBanner />
      <TokenSearch />
      <EthTrending />
      <SolTrending />
      <TonTrending />
      
      <CoinSearch coins={coins} />
      <br />
      <br />
      <Promo2 />
      <TrendingCoins />
    </div>
  );
}

export default HomePage;