import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

const Promotion = () => {
  const promotions = [
    {
      id: 1,
      header: 'Add Coin',
      image: 'DEFIGEM-Trending.png',
      description: '1 day $50 | 3 day $100 | 7 day $250 (Twitter + TG Channel + Pin).',
      link: 'https://t.me/DefiDevelope',
    },
    {
      id: 2,
      header: 'Wide Banner',
      image: 'Wide-Banner.png',
      description: '1 day $50 | 3 day $100 | 7 day $200 (Twitter + TG Channel + Pin).',
      link: 'https://t.me/DefiDevelope',
    },
    {
      id: 3,
      header: 'Special Ad Board',
      image: 'Special-Wide-Ad-Board.png',
      description: '1 day $30 | 3 day $70 | 7 day $150 (Twitter + TG Channel + Pin).',
      link: 'https://t.me/DefiDevelope',
    },
    {
      id: 4,
      header: 'Press Release & Scroll Banner',
      image: 'PR-Press-Realease-And-Scroll-Banner.png',
      description: 'A Honest review about your project (Indexed) $300',
      link: 'https://t.me/DefiDevelope',
    },
  ];

  return (
    <div className='rounded-div my-12 py-8'>
      {/* Grid layout with 2 columns */}
      <div className='grid md:grid-cols-2 gap-8'>
        {promotions.map((promo) => (
          <div key={promo.id} className='rounded-lg shadow-lg p-6'>
            {/* Wide centered header */}
            <div className='w-full text-center mb-4'>
              <h2 className='text-xl font-bold dark:text-white text-black'>{promo.header}</h2>
            </div>

            {/* Full-width image */}
            <div className='w-full mb-4'>
              <img className='w-full h-auto object-cover rounded-lg' src={promo.image} alt='Promotion' />
            </div>

            {/* Dark-mode compatible description */}
            <p className='font-bold dark:text-white text-black mb-4'>{promo.description}</p>

            {/* Learn More button */}
            <a
              href={promo.link}
              className='w-full flex items-center justify-center bg-teal-500 text-white py-2 px-4 rounded-lg hover:bg-teal-600 transition duration-300' target="_blank" rel="noreferrer"
            >
              Order Now <FaArrowRight className='ml-2' />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Promotion;